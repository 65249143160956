import React from "react";
import DraggableTable from "../DraggableTable";

const SelectedActivitiesList = (props) => {
  const serializeItems = (props.selectedActivities || []).map((item, ix) => ({
    id: `selected-activity-${ix}`,
    contents: (
      <React.Fragment>
        <td>
          <i
            onClick={() => props.removeFromSelected(ix)}
            className="btn btn-primary btn-circle btn-sm"
            style={{  lineHeight: 0,
              padding: "5px 6px",
              margin: 2,
              height: 26,
              width: 26 }}
            title="Remove from Collection"
          >
            <span
              className="fa fa-trash-o"
              aria-hidden="true"
              style={{ height: 10, width: 10, paddingRight: 1, paddingTop: 2 }}
            />
          </i>
        </td>
        <td>{item.name}</td>
        <td>{item.description}</td>
        <td>{item.activity_type}</td>
        <td>
          <input
            type="checkbox"
            checked={item.skippable ? true : false}
            onChange={() => onCheckToggle(ix)}
          />

          {/* start form data */}
          <input
            type="hidden"
            value={item.id}
            name="program[program_activities_attributes][][activity_id]"
          />

          <input
            type="hidden"
            value={ix}
            name="program[program_activities_attributes][][position]"
          />

          <input
            type="hidden"
            value={props.swappable}
            name="program[program_activities_attributes][][swappable]"
          />

          {item.activities_selected_id && (
            <input
              type="hidden"
              value={item.activities_selected_id}
              name="program[program_activities_attributes][][id]"
            />
          )}

          <input
            type="hidden"
            value={item.skippable}
            name="program[program_activities_attributes][][skippable]"
          />
          <input
            type="hidden"
            value={item.program_activity_type}
            name="program[program_activities_attributes][][program_activity_type]"
          />
          {/* end form data */}
        </td>
      </React.Fragment>
    ),
  }));

  const onCheckToggle = (toggleIndex) => {
    const result = Array.from(props.selectedActivities || []);
    const [removed] = result.splice(toggleIndex, 1);
    const replacement = { ...removed, skippable: !removed.skippable };
    result.splice(toggleIndex, 0, replacement);
    props.setSelected(result);
  };

  const onReorder = (startIndex, endIndex) => {
    const result = Array.from(props.selectedActivities || []);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    props.setSelected(result);
  };

  return (
    <div className="row col-md-12">
      <table
        className="table table-condensed table-striped w-100 d-block d-md-table"
        id="selected_activities_list"
      >
        <thead>
          <tr>
            <th />
            <th>Name</th>
            <th>Description</th>
            <th>Type</th>
            <th>Skippable</th>
          </tr>
        </thead>
        <DraggableTable items={serializeItems} onReorder={onReorder} />
      </table>
    </div>
  );
};

export default SelectedActivitiesList;
